<template>
    <div class="bePutInStorage-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
        <el-dialog title="选择文件" :visible.sync="dialogVisibleUpload" @close="closeFun()" :close-on-click-modal="false">
            <div class="btn-list">
                <div class="up_add_bg" @click='onImgFunBtn'>选择文件
                    <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                </div>
                <el-button class="download-btn"  @click="downloadExcel">下载模板</el-button>
            </div>
            <div class="upload_fileName">文件名称：{{fileName}}</div>
            <!-- .xls .xslx 格式 -->
            <div class="dialog_bottom">
                <el-button type="primary" :disabled="sureBtnFlag" @click="commitFile">确认</el-button>
                <el-button type="primary" @click="closeFun">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    import axios from "axios";
    import Vue from "vue";
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                fileName: '',
                formData: new FormData(),
                file: {}, //文件数据  
                dialogVisibleUpload: false,
                sureBtnFlag: false,
                opt: {
                    search: [{
                            key: "merchantId",
                            label: "商家名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: []
                            }
                        }, {
                            key: "name",
                            label: "商品名称",
                            maxlength: 32,
                            rules: [{
                                validator: validItemsName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "code",
                            label: "商品编号",
                            rules: [{
                                validator: validCode,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "category",
                            label: "商品品类",
                        },
                        {
                            key: "inNumber",
                            label: "入库单号",
                        },
                        {
                            key: "date",
                            label: "入库时间",
                            type: 'data-range',
                        },
                        {
                            key: "inName",
                            label: "入库人",
                        },
                        {
                            key: "supplierId",
                            label: "供应商名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: []
                            }
                        },{
                            key: "distributorId",
                            label: "经销商名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: []
                            }
                        }],
                    columns: [{
                            label: "商家名称",
                            key: "merchantName"
                        }, {
                            label: "商品名称",
                            key: "goodsName"
                        },
                        {
                            label: "商品编号",
                            key: "goodsCode"
                        },
                        {
                            label: "商品品类",
                            key: "goodsCategory"
                        },
                        {
                            label: "入库单号",
                            key: "inNumber"
                        },
                        {
                            label: "入库人",
                            key: "inName"
                        },
                        {
                            label: "入库时间",
                            key: "createDate"
                        },
                        {
                            label: "入库商品数（件）",
                            key: "inCount"
                        },
                        {
                            label: "供应商名称",
                            key: "supplierName"
                        },
                        {
                            label: "经销商名称",
                            key: "distributorName"
                        }
                    ],
                    buttons: [{
                            type: 0,
                            name: "新增入库",
                            icon: "el-icon-circle-plus-outline",
                            on() {
                                _this.$router.push('/main/storehouse/bePutInStorage/detail')
                            }
                        },
                        {
                            type: 3,
                            name: "批量导入",
                            icon: "el-icon-circle-plus-outline",
                            on() {
                                _this.dialogVisibleUpload = true;
                                _this.fileName = "";
                            }
                            },
                        {
                            type: 2,
                            on(data) {
                                if (data.ids.length > 1) {
                                    return _this.$message({
                                        showClose: true,
                                        message: "不支持多个删除,只支持单个删除!",
                                        type: "warning"
                                    });
                                }
                                const h = _this.$createElement;
                                _this
                                    .$msgbox({
                                        title: "删除提示",
                                        message: h("p", null, [
                                            h(
                                                "span", {
                                                    style: "color: #333;font-size: 16px;margin-left:98px"
                                                },
                                                "确定要删除吗？"
                                            )
                                        ]),
                                        showCancelButton: true,
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消"
                                    })
                                    .then(action => {
                                        _this.post("mall-service/v1/warehouse/in/delete", data.ids).then(
                                        res => {
                                            _this.$message({
                                                showClose: true,
                                                message: "删除成功！",
                                                type: "success"
                                            });
                                            data.refresh();
                                        });
                                    });
                            }
                        }
                    ],
                    exportButtons:[{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/warehouse/inventoryIn/export",
                        listName: '入库列表'
                    }],
                },
                categoryList: [],
                merchantFlag: false,
                isSellOnBusinessFalg: false,
            };
        },
        created() {
            console.log("bePutInStorage created!!");
        },
        activated() {
            console.log("bePutInStorage activated!!");
            this.merchantFlag = this.getLoginType('loginType');
            this.isSellOnBusinessFalg = this.isSellOnBusiness();
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
                if(this.isSellOnBusinessFalg) {
                    this.opt.search.forEach((item,index) => {
                        if(item.key == 'supplierId') {
                            this.opt.search.splice(index,1)
                        }
                    })
                    this.opt.columns.forEach((item, index) => {
                        if (item.key == 'supplierName') {
                            this.opt.columns.splice(index, 1);
                        }
                    })
                }else {
                    this.opt.search.forEach((item,index) => {
                        if(item.key == 'distributorId') {
                            this.opt.search.splice(index,1)
                        }
                    })
                    this.opt.columns.forEach((item, index) => {
                        if (item.key == 'distributorName') {
                            this.opt.columns.splice(index, 1);
                        }
                    })
                }

            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                    if(item.key == 'distributorId') {
                        this.opt.search.splice(index,1)
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'distributorName') {
                            this.opt.columns.splice(index, 1);
                    }
                })
            }
            this.getMerchantList()
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm,
                    inStartDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
                    inEndDate: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
                };
                this.post("mall-service/v1/warehouse/query/in/list", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    obj.data.total = res.data.data.total;
                    obj.data.data = res.data.data.data;
                    res.data.data.data.forEach(item => {
                        item.supplierName = item.supplierName ? item.supplierName : '--';
                        item.distributorName = item.distributorName ? item.distributorName : '--';
                    })
                    
                    opt.cb(obj.data);
                });
            },
            // 获取供应商列表
            getMerchantList() {
                let roleType = this.isSellOnBusinessFalg ? 3 : 2;
                cabinetUtil.getMerchantList({roleType: roleType}).then(res => {
                    if(this.isSellOnBusinessFalg) {
                        this.opt.search.forEach(item => {
                            if(item.key == 'distributorId') {
                                item.opt.list = res
                            }
                        })
                    }else {
                        this.opt.search.forEach(item => {
                            if(item.key == 'supplierId') {
                                item.opt.list = res
                            }
                        })
                    }
                    
                })
            },
            onImgFunBtn() {
                document.getElementById('fileupload').click();
            },
            //选择文件
            addFile: function (e) {
                var _this = this;
                let inputDOM = this.$refs.inputer;
                this.file = inputDOM.files[0];
                let len = this.file.length;
                let size = Math.floor(this.file.size / 1024);
                if (size > 50 * 1024 * 1024) {
                alert("请选择50M以内的文件！");
                return false;
                }
                this.fileName = document.getElementById('fileupload').files[0].name;
                this.formData.append("file", this.file);
                this.sureBtnFlag = false;
                e.target.value = '';
            },
            commitFile(){
                var _this = this;
                if (!this.fileName) {
                    return this.$message({
                        message: "请选择文件",
                        type: "warning"
                        });
                }
                this.sureBtnFlag = true;
                axios({
                    method: "POST",
                    // url: this.BASE_URL + "/downloadFile/add",
                    url: _this.UPLOAD_URL + "/mall-service/v1/warehouse/inventoryIn/import",
                    data: this.formData,
                    headers: {
                    accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
                    securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem("securityKey"),
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    this.formData = new FormData();
                    if (response.data.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '导入成功!'
                        })
                        this.sureBtnFlag = false;
                        this.dialogVisibleUpload = false;
                        this.$refs.myList.get();
                    } else {
                        this.$message({
                            type: 'warning',
                            message: response.data.msg
                        })
                        this.sureBtnFlag = true;
                    }
                })
                .catch((error) => {
                    this.sureBtnFlag = false;
                    this.formData = new FormData();
                });
            },
            downloadExcel(){
                let url = ''
                if (Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL == 'https://apartment-api.wavewisdom.com') {
                    url = 'https://oss-image-prod.wfyundata.com/excel/%E5%85%A5%E5%BA%93%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%8D%95%E6%A8%A1%E6%9D%BF.xls'
                } else {
                    url = 'http://oss-image-prod.wfyundata.com/excel/%E5%85%A5%E5%BA%93%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%8D%95%E6%A8%A1%E6%9D%BF.xls'
                }
                axios.get(url, {         
                    responseType: 'blob',          
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));            
                    const link = document.createElement('a');            
                    let fname = '入库批量导入单模板.xls';            
                    link.href = url;            
                    link.setAttribute('download', fname);            
                    document.body.appendChild(link);            
                    link.click();          
                }).catch(error => {            
                    console.log('error:'+JSON.stringify(error))          
                });     
            },
            closeFun() {
                this.sureBtnFlag = false;
                this.dialogVisibleUpload = false;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .bePutInStorage-list {
        height: calc(100% - 130px);
    }
    .dialog_bottom {
        margin-top: 23px;
        width: 100%;
        text-align: center;

        .el-button {
        width: 80px !important;
        height: 36px !important;
        padding-top: 10px;
        }
    }

    .up_add_bg {
        width: 95px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #CFCFD7;
        border-radius: 6px;
        cursor: pointer;

        .upload-btn {
        opacity: 0;
        }
    }
    .upload_fileName {
        padding-top: 10px;
        padding-right: 5px;
    }
    .btn-list{
        display: flex;
    }
    .download-btn{
        margin-left: 10px;
    }
</style>