<template>
    <div class="stock-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
        <el-dialog :title="stockTitle" :visible.sync="dialogVisibleStock" :close-on-click-modal="false">
            <div class="editBox">
                <el-form ref="myForm" :model="myForm" :rules="rules">
                    <el-form-item label-width="120px" label="商品名称：">
                        <div>{{myForm.goodsName}}</div>
                    </el-form-item>
                    <el-form-item label-width="120px" label="商品编号：">
                        <div>{{myForm.goodsCode}}</div>
                    </el-form-item>
                    <el-form-item label-width="111px" label="剩余库存:" prop="remainder">
                        <el-input style="width: 320px;display: inline-block;" placeholder="请输入库存"
                            v-model="myForm.remainder" clearable>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog-footer">
                <el-button type="primary" @click="submit()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,32}$/s;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    let stockVli = (rule, value, callback) => {
        var reg = /(^[0-9]{1}[0-9]*$)/
        if (!reg.test(value)) {
            callback(
                new Error("请输入整数")
            );
        } else {
            callback();
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                stockTitle: '编辑库存',
                dialogVisibleStock: false,
                totalOf: '',
                myForm: {
                    remainder: 0,
                    goodsName: '',
                    id: '',
                },
                rules: {
                    remainder: [{
                        trigger: ['change', 'blur'],
                        validator: stockVli
                    }]
                },
                opt: {
                    search: [{
                            key: "merchantId",
                            label: "商家名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: []
                            }
                        }, {
                            key: "name",
                            label: "商品名称",
                            maxlength: 32,
                            rules: [{
                                validator: validItemsName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "code",
                            label: "商品编号",
                            rules: [{
                                validator: validCode,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "category",
                            label: "商品品类",
                        }],
                    columns: [{
                            label: "商家名称",
                            key: "merchantName"
                        }, {
                            label: "商品名称",
                            key: "goodsName"
                        },
                        {
                            label: "商品编号",
                            key: "goodsCode"
                        },
                        {
                            label: "商品图片",
                            key: "goodsImages",
                            type: "img-shop"
                        },
                        {
                            label: "商品品类",
                            key: "goodsCategory"
                        },
                        {
                            label: "成本价（元/件）",
                            key: "goodsCost"
                        },
                        {
                            label: "经销价（元/件）",
                            key: "distributorPrice"
                        },
                        {
                            label: "销售价（元/件）",
                            key: "goodsPrice"
                        },
                        {
                            label: "总库存（件）",
                            key: "totalOf"
                        },
                        {
                            label: "剩余库存（件）",
                            key: "remainder"
                        },
                        {
                            label: "销量（件）",
                            key: "sales"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "编辑库存",
                                    on(row) {
                                        _this.dialogVisibleStock = true;
                                        _this.myForm.goodsName = row.goodsName;
                                        _this.myForm.goodsCode = row.goodsCode;
                                        _this.myForm.remainder = row.remainder;
                                        _this.totalOf = row.totalOf;
                                        _this.myForm.id = row.id;
                                    }
                                }]
                            }
                        }
                    ],
                    exportButtons: [{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/warehouse/inventory/export",
                        listName: '库存列表'
                    }],
                },
                categoryList: [],
                merchantFlag: false,
            };
        },
        created() {
            console.log("stock created!!");
        },
        activated() {
            console.log("stock activated!!");
            let merchantType = this.cache.getLS('userInfo').merchantType;
            let loginType = this.cache.getLS('loginType');
            if (loginType == 1) {
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'action') {
                        this.opt.columns.splice(index, 1)
                    }
                })
            }
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
                if(merchantType == 3) {
                    // 经销类的商家登录 不显示成本价
                    this.opt.columns.forEach((item,index) => {
                        if(item.key == 'goodsCost') {
                           this.opt.columns.splice(index,1) 
                        }
                    })
                }else {
                    // 普通商家登录 不显示经销价
                    this.opt.columns.forEach((item,index) => {
                        if(item.key == 'distributorPrice') {
                           this.opt.columns.splice(index,1) 
                        }
                    })
                }
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                this.post("mall-service/v1/warehouse/query/list", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    if(res.data.code == 0) {
                        obj.data.total = res.data.data.total;
                        if(res.data.data.data && res.data.data.data.length > 0) {
                            res.data.data.data.forEach(item => {
                                item.goodsCost = item.goodsCost == null ? '--' : item.goodsCost;
                                item.distributorPrice = item.distributorPrice == null ? '--' : item.distributorPrice;
                            })
                        }
                        obj.data.data = res.data.data.data;
                    }
                    opt.cb(obj.data);
                });
            },
            submit() {

                this.$refs['myForm'].validate((valid) => {
                    if (valid) {
                        if (this.myForm.remainder > this.totalOf) {
                            this.$message({
                                message: '剩余库存不能大于总库存！',
                                type: 'warning'
                            })
                            return
                        }
                        let dto = {
                            id: this.myForm.id,
                            remainder: this.myForm.remainder,
                        }
                        this.post('mall-service/v1/warehouse/update/total', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '编辑库存成功！',
                                    type: 'success',
                                })
                                this.dialogVisibleStock = false;
                                this.$refs.appList.get(); //刷新列表
                            }
                        })
                    }

                })

            },
        }
    };
</script>
<style lang="scss" scoped>
    .stock-list {
        height: calc(100% - 130px);
    }

    .dialog-footer {
        text-align: center;
    }
</style>