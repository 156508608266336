<template>
    <div class="return-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,32}$/s;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [{
                        key: "merchantId",
                        label: "商家名称",
                        type: "remoteSearchById",
                        opt: {
                        list: [],
                        change(data) {
                            _this.getStoreList(data)
                        }
                        }
                    }, {
                        key: "groupId",
                        label: "门店名称",
                        type: "remoteSearchById",
                        opt: {
                            list: []
                        }
                    },{
                        key: "userName",
                        label: "操作人姓名",
                    },{
                        key: "name",
                        label: "商品名称",
                        maxlength: 32,
                        rules: [{
                            validator: validItemsName,
                            trigger: ['blur']
                        }]
                    },
                    {
                        key: "code",
                        label: "商品编号",
                        rules: [{
                            validator: validCode,
                            trigger: ['blur']
                        }]
                    },
                    {
                        key: "date",
                        label: "退还时间",
                        type: "data-range"
                    }],
                    columns: [{
                            label: "商家名称",
                            key: "merchantName"
                        }, {
                            label: "门店名称",
                            key: "groupName"
                        },{
                            label: "商品名称",
                            key: "goodsName"
                        },
                        {
                            label: "商品编号",
                            key: "goodsCode"
                        },
                        {
                            label: "商品图片",
                            key: "goodsImages",
                            type: "img-shop"
                        },
                        {
                            label: "商品品类",
                            key: "goodsCategory"
                        },
                        {
                            label: "商品售价（元/件）",
                            key: "goodsPrice"
                        },
                        {
                            label: "操作人姓名",
                            key: "returnUserName"
                        },
                        {
                            label: "操作人手机号",
                            key: "returnUserPhone"
                        },
                        {
                            label: "退还仓库时间",
                            key: "createDate"
                        },
                        {
                            label: "门店件数（原）",
                            key: "originalNumber"
                        },
                        {
                            label: "退回件数",
                            key: "returnCount"
                        },
                    ],
                    exportButtons: [{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/warehouse/inventoryReturn/export",
                        listName: '退还仓库记录'
                    }],
                },
                categoryList: [],
                merchantFlag: false,
            };
        },
        created() {
            console.log("return-list created!!");
        },
        activated() {
            console.log("return-list activated!!");
            let merchantType = this.cache.getLS('userInfo').merchantType;
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm,
                    startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
                    endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
                };
                this.post("/mall-service/v1/warehouse/query/return/pageList", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    if(res.data.code == 0) {
                        obj.data.total = res.data.data.total;
                        if(res.data.data.data && res.data.data.data.length > 0) {
                            res.data.data.data.forEach(item => {
                                item.returnUserPhone = item.returnUserPhone ? item.returnUserPhone : '--'
                                item.groupName = item.groupName ? item.groupName : '--'
                            })
                        }
                        obj.data.data = res.data.data.data;
                    }
                    opt.cb(obj.data);
                });
                if (opt.searchForm.merchantId) {
                    this.getStoreList(opt.searchForm.merchantId);
                } else {
                    this.getStoreList();
                }
            },
            getStoreList(data) {
                let dto = {
                    currentPage: 1,
                    size: 99999,
                };
                if (data) {
                    dto.merchantId = data
                }
                this.post("/mall-service/v1/counterStores/getAllList", dto, {
                    isUseResponse: true,
                }).then(res => {
                    let storeList = res.data.data;
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                    this.opt.search.forEach(item => {
                    if (item.key == 'groupId') {
                        item.opt.list = storeList;
                    }
                    })
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .return-list {
        height: calc(100% - 130px);
    }

    .dialog-footer {
        text-align: center;
    }
</style>