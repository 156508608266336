var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-list" },
    [
      _c("app-list", {
        ref: "appList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.stockTitle,
            visible: _vm.dialogVisibleStock,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleStock = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "editBox" },
            [
              _c(
                "el-form",
                {
                  ref: "myForm",
                  attrs: { model: _vm.myForm, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "120px", label: "商品名称：" } },
                    [_c("div", [_vm._v(_vm._s(_vm.myForm.goodsName))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "120px", label: "商品编号：" } },
                    [_c("div", [_vm._v(_vm._s(_vm.myForm.goodsCode))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "111px",
                        label: "剩余库存:",
                        prop: "remainder"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "320px",
                          display: "inline-block"
                        },
                        attrs: { placeholder: "请输入库存", clearable: "" },
                        model: {
                          value: _vm.myForm.remainder,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "remainder", $$v)
                          },
                          expression: "myForm.remainder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }